import { useState } from 'react';
import { useAPI } from './useAPI';

export const useProjectInterestEmail = () => {
  const { publicCreateProjectInterest } = useAPI();

  const [project, setProject] = useState<{ title: string, id: number, type: string }>();

  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const [error, setError] = useState(false);
  const [isLoading, setIsloading] = useState(true);

  const getParamsValue = (params: URLSearchParams) => {
    const projectId = params.get('interest') as string;
    const userEmail = decodeURIComponent(params.get('email') as string);
    const type = params.get('type') as string;
    const source = params.get('source') as string;

    return {
      projectId, userEmail, type, source,
    };
  };

  const createProjectInterest = async (params: URLSearchParams) => {
    try {
      const {
        userEmail, projectId, type, source,
      } = getParamsValue(params);

      if (!type) throw new Error('Invaild information');
      const interestType = type === 'uneligible' ? 'not_eligible' : 'not_interested';
      const projectInfo = await publicCreateProjectInterest(projectId, userEmail, interestType, source);
      if (projectInfo.error) throw new Error(projectInfo.error);
      setProject(projectInfo.project);
      setIsloading(false);
    } catch (err) {
      setError(true);
      setIsloading(false);
    }
  };

  const deleteProjectInterest = async (params: URLSearchParams) => {
    try {
      const { userEmail, projectId } = getParamsValue(params);
      const projectInfo = await publicCreateProjectInterest(projectId, userEmail, 'delete project interested');

      setProject(projectInfo.project);
      setToastMessage('restored');
      setToastIsOpen(true);
    } catch (err) {
      setError(true);
    }
  };

  return {
    project,
    getParamsValue,
    createProjectInterest,
    deleteProjectInterest,
    toastIsOpen,
    toastMessage,
    setToastIsOpen,
    error,
    isLoading,
  };
};
