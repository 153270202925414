export const PROJECT_INTEREST_EMAIL = {
  TYPE: {
    STUDY: 'Study: ',
    PPO: 'Patient Partner Research Opportunity: ',
  },
  FAULTED: 'Failed to remove. Please try again',
  SUCCESS: 'You have successfully removed this study from your match list.',
  VIEW_MATCHES: 'View All My Matches',
  UNDO_MESSAGE: "Didn't mean to remove it?",
  UNDO_BUTTON: 'Restore Match',
};
