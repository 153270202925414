import React from 'react';
import { useCreateResearcherHook } from './component/useCreateResearcherHook';
import { TextH1 } from '../../components/Text';
import LoginHeader from '../../components/LoginHeader';
import {
  HeaderStyled, CreateResearcherWrapper, ProgressBarStyled, ProgressBarWrapper, HeaderTextWrapper, MainStyled,
} from '../../styles/ResearcherCreateStyles';
import { fonts } from '../../styles/theme';
import CreateForm from './component/CreateForm';
import InfoForm from './component/InfoForm';

const ResearcherCreate = () => {
  const hook = useCreateResearcherHook();

  return (
    <CreateResearcherWrapper>
      <LoginHeader isOpen={hook.isOpen} setIsOpen={hook.setIsOpen} />
      <HeaderStyled>
        <ProgressBarWrapper>
          <ProgressBarStyled futureStep={hook.currentStep < 0} />
          <ProgressBarStyled futureStep={hook.currentStep < 1} />
        </ProgressBarWrapper>
        <HeaderTextWrapper>
          <TextH1 font={fonts.font1.H26B}>First, we need to create your Researcher account</TextH1>
        </HeaderTextWrapper>
      </HeaderStyled>
      <MainStyled>
        {hook.currentStep === 0 && (
          <CreateForm
            email={hook.email}
            setEmail={hook.setEmail}
            password={hook.password}
            setPassword={hook.setPassword}
            confirmPassword={hook.confirmPassword}
            setConfirmPassword={hook.setConfirmPassword}
            handleSubmit={hook.handleSubmit}
            researcherInfoError={hook.researcherInfoError}
            checkEmail={hook.checkEmail}
            handleSingleValidation={hook.handleSingleValidation}
            hookPasswordRequirements={hook.passwordHook.hookPasswordRequirements}
            hookHandlePasswordChecking={hook.passwordHook.hookHandlePasswordChecking}
            hookDisplayValidationText={hook.passwordHook.hookDisplayValidationText}
          />
        )}
        {hook.currentStep === 1 && (
          <InfoForm
            researcherInfo={hook.researcherInfo}
            researcherInfoError={hook.researcherInfoError}
            handleSubmit={hook.handleInfoSubmit}
            handleChange={hook.handleChange}
            setAgreeTermsOfUse={hook.setAgreeTermsOfUse}
            agreeTermsOfUse={hook.agreeTermsOfUse}
            receiveNews={hook.receiveNews}
            setReciveNews={hook.setReciveNews}
            universities={hook.universities || []}
            healthAuthorities={hook.healthAuthority}
            captchaHandler={hook.captchaHandler}
            captchaRef={hook.captchaRef}
            handleSingleValidation={hook.handleSingleValidation}
            setSmsContsent={hook.setSmsConsent}
            smsConsent={hook.smsConsent}
          />
        )}

      </MainStyled>
    </CreateResearcherWrapper>
  );
};
export default ResearcherCreate;
