import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useProjectDetails } from './useProjectDetails';
import { SOURCE_TYPES } from '../utils';

export const usePPODetails = () => {
  const location: any = useLocation();

  const {
    user,
    params,
    project,
    setProject,
    getProjectInfo,
    isLoading,
    currentTab,
    setCurrentTab,
    researcherTabOptions,
    principalInvestigator,
    interested,
    getInterestedVolunteers,
    handleFollowUpUpdate,
    filterVolunteerHandler,
    result,
    getProjectResult,
    hideButtonStatus,
    enrollentStatusFilter,
    interestSearchValue,
    setInterestSearchValue,
    selectedInterests,
    setSelectedInterestsHandler,
    updateProjectInterestsHandler,
  } = useProjectDetails();

  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [source, setSource] = useState('');

  useEffect(() => {
    if (source === undefined || source === '') return;
    if (location.search && location.search.includes('results') && params.id) {
      setCurrentTab('Results');
      getProjectResult(parseInt(params.id, 10), 'ppo');
      getProjectInfo(parseInt(params.id, 10), 'ppo', source);
    } else if (params.id) {
      getProjectInfo(parseInt(params.id, 10), 'ppo', source);
    }
  }, [params, source]);

  useEffect(() => {
    setSource(location.state?.source || SOURCE_TYPES.APP);
  }, []);

  return {
    user,
    project,
    setProject,
    isLoading,
    currentTab,
    setCurrentTab,
    researcherTabOptions,
    principalInvestigator,
    interested,
    getInterestedVolunteers,
    handleFollowUpUpdate,
    filterVolunteerHandler,
    result,
    getProjectResult,
    hideButtonStatus,
    enrollentStatusFilter,
    interestSearchValue,
    setInterestSearchValue,
    selectedInterests,
    setSelectedInterestsHandler,
    updateProjectInterestsHandler,
    toastIsOpen,
    setToastIsOpen,
    source,
  };
};
