import * as Yup from 'yup';
import { domainWhitelist } from './domainWhiteList';

const ExtensionsSchema = {
  extensions: Yup.number()
    .integer('Please enter a valid extension number')
    .min(0, 'Please enter a valid extension number')
    .max(99999999, 'Please enter a valid extension number')
    .typeError('Please enter a valid extension number')
    .nullable(),
};

export const ResearcherEmailSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter a valid email address')
    .email('Please enter a valid email address')
    .matches(
      new RegExp(
        `^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\\.)?[a-zA-Z]+\\.)?(${domainWhitelist.join('|')})$`,
      ),
      'This email domain is not allowed. REACH BC is currently available for researchers employed at public institutions in BC. If you are unable to enter your institutional email, please contact admin@reachbc.ca.',
    ),
});

export const VolunteerEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
});

export const PasswordOnBlurSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Please enter a valid password')
    .max(128, 'Please enter a valid password. The field allows up to 128 characters.')
    .matches(/[a-z]/, 'Please enter a valid password')
    .matches(/[A-Z]/, 'Please enter a valid password')
    .matches(/[0-9]/, 'Please enter a valid password')
    .matches(/[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/, 'Please enter a valid password')
    .required('Please enter a valid password'),
  password_confirm: Yup.string()
    .required('These two passwords do not match. Please try again.')
    .oneOf([Yup.ref('password'), null], 'These two passwords do not match. Please try again.'),
});

export const CreateResarcherSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .matches(
      new RegExp(
        `^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\\.)?[a-zA-Z]+\\.)?(${domainWhitelist.join('|')})$`,
      ),
      'This email domain is not allowed. REACH BC is currently available for researchers employed at public institutions in BC. If you are unable to enter your institutional email, please contact admin@reachbc.ca.',
    )
    .required('Please enter a valid email address'),
  password: Yup.string()
    .min(8, 'Please enter a valid password')
    .max(128, 'Please enter a valid password. The field allows up to 128 characters.')
    .matches(/[a-z]/, 'Please enter a valid password')
    .matches(/[A-Z]/, 'Please enter a valid password')
    .matches(/[0-9]/, 'Please enter a valid password')
    .matches(/[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/, 'Please enter a valid password')
    .required('Please enter a valid password'),
  password_confirm: Yup.string()
    .required('Those two passwords do not match. Please try again.')
    .oneOf([Yup.ref('password'), null], 'Those two passwords do not match. Please try again.'),
});

export const ResearcherOtherFields = Yup.object().shape({
  positionOther: Yup.string()
    .required('Please specify your position')
    .max(50, 'Please enter a valid position. The field allows up to 50 characters.').nullable(),
  position_other: Yup.string()
    .required('Please specify your position')
    .max(50, 'Please enter a valid position. The field allows up to 50 characters.').nullable(),
  academicAffiliationOther: Yup.string()
    .required('Please enter your university’s name')
    .max(50, 'Please enter your university’s name. The field allows up to 50 characters.').nullable(),
  academic_affiliation_other: Yup.string()
    .required('Please enter your university’s name')
    .max(50, 'Please enter your university’s name. The field allows up to 50 characters.').nullable(),
});

export const ResearcherInfoSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1, 'Please enter a valid first name')
    .max(50, 'Please enter a valid first name. The field allows up to 50 characters.')
    .required('Please enter a valid first name'),
  lastName: Yup.string()
    .min(1, 'Please enter a valid last name')
    .max(50, 'Please enter a valid last name. The field allows up to 50 characters.')
    .required('Please enter a valid last name'),
  phoneNumber: Yup.string()
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number. The field allows only 10 characters.')
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    ),
  mobilePhoneNumber: Yup.string()
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number. The field allows only 10 characters.')
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    )
    .required('Please enter a valid phone number'),
  position: Yup.string().required('Please choose a position'),
  positionOther: Yup.string().when('position', {
    is: 'Other',
    then: Yup.string()
      .required('Please enter a valid position')
      .min(2, 'Please enter a valid position')
      .max(50, 'Please enter a valid position. The field allows up to 50 characters.')
      .nullable(),
  }),
  ha_affiliation_id: Yup.string().required('Please choose a health authority affiliation'),
  academic_affiliation_id: Yup.string().required('Please choose a university affiliation'),
  academicAffiliationOther: Yup.string().when('academic_affiliation_id', {
    is: '10',
    then: Yup.string()
      .required('Please enter your university’s name')
      .min(2, 'Please enter your university’s name')
      .max(50, 'Please enter your university’s name. The field allows up to 50 characters.')
      .nullable(),
  }),
  extension: ExtensionsSchema.extensions,
  acceptTerms: Yup.boolean()
    .oneOf([true], 'Please accept the Terms and Conditions to continue')
    .required('Please accept the Terms and Conditions to continue'),
  terms_of_use: Yup.boolean()
    .oneOf([true], 'Please accept the Terms and Conditions to continue')
    .required('Please accept the Terms and Conditions to continue'),
  learnAboutReachBC: Yup.string().required('Please let us know how you heard about ReachBC'),
  learnAboutReachBCOther: Yup.string(),
});

export const CreateVolunteerSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(1, 'Please enter a valid first name')
    .max(50, 'Please enter a valid first name. The field allows up to 50 characters.')
    .required('Please enter a valid first name'),
  lastName: Yup.string()
    .min(1, 'Please enter a valid last name')
    .max(50, 'Please enter a valid last name. The field allows up to 50 characters.')
    .required('Please enter a valid last name'),
  phoneNumber: Yup.string()
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number. The field allows only 10 characters.')
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    )
    .required('Please enter a valid phone number'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
  emailExists: Yup.boolean()
    .required()
    .oneOf([false], 'Email address is already in use. Please correct the address'),
  password: Yup.string()
    .min(8, 'Please enter a valid password')
    .max(128, 'Please enter a valid password. The field allows up to 128 characters.')
    .matches(/[a-z]/, 'Please enter a valid password')
    .matches(/[A-Z]/, 'Please enter a valid password')
    .matches(/[0-9]/, 'Please enter a valid password')
    .matches(/[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/, 'Please enter a valid password')
    .required('Please enter a valid password'),
  passwordSimilar: Yup.boolean()
    .required()
    .oneOf(
      [false],
      'Sorry, your password is too similar to either your email address, first name or last name',
    ),
  passwordConfirm: Yup.string()
    .required('Those two passwords do not match. Please try again.')
    .oneOf([Yup.ref('password'), null], 'Those two passwords do not match. Please try again.'),
  birthDateMonth: Yup.string()
    .min(2, 'Please enter a valid month')
    .max(2, 'Please enter a valid month')
    .matches(/(0[1-9]|1[0-2])/, 'Please enter a valid month')
    .typeError('Please enter a valid month')
    .required('Please enter a valid month'),
  birthDateDay: Yup.string()
    .min(2, 'Please enter a valid day')
    .max(2, 'Please enter a valid day')
    .matches(/(0[1-9]|[12]\d|3[01])/, 'Please enter a valid day')
    .typeError('Please enter a valid day')
    .required('Please enter a valid day'),
  birthDateYear: Yup.string()
    .min(4, 'Please enter a valid year')
    .max(4, 'Please enter a valid year')
    .matches(/([12]\d{3})/, 'Please enter a valid year')
    .typeError('Please enter a valid year')
    .required('Please enter a valid year'),
  siteCode: Yup.string().typeError('Please enter a valid Site Code'),
  healthCategories: Yup.array()
    .min(1, 'Please select at least one health research area option')
    .required('Please select at least one health research area option'),
  learnAboutReachBC: Yup.string().required('Please let us know how you heard about ReachBC'),
  learnAboutReachBCOther: Yup.string(),
});

export const AuthCodeSchema = Yup.object().shape({
  code: Yup.string()
    .min(6, 'Please enter a valid code 6 digits')
    .max(6, 'Please enter a valid code 6 digits')
    .required('Please enter a valid code'),
});

export const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
  password: Yup.string()
    .min(8, 'Please enter a valid password')
    .max(128, 'Please enter a valid password. The field allows up to 128 characters.')
    .matches(/[a-z]/, 'Please enter a valid password')
    .matches(/[A-Z]/, 'Please enter a valid password')
    .matches(/[0-9]/, 'Please enter a valid password')
    .matches(/[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/, 'Please enter a valid password')
    .required('Please enter a valid password'),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
});

export const ResetPasswordOnBlurSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Please enter your old password'),
  password: Yup.string()
    .min(8, 'Please enter a valid password')
    .max(128, 'Please enter a valid password. The field allows up to 128 characters.')
    .matches(/[a-z]/, 'Please enter a valid password')
    .matches(/[A-Z]/, 'Please enter a valid password')
    .matches(/[0-9]/, 'Please enter a valid password')
    .matches(/[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/, 'Please enter a valid password')
    .required('Please enter a valid password'),
  confirmPassword: Yup.string()
    .required('Those two passwords do not match. Please try again.')
    .oneOf([Yup.ref('password'), null], 'Those two passwords do not match. Please try again.'),
});

export const ForgotResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Please enter a valid password')
    .max(128, 'Please enter a valid password. The field allows up to 128 characters.')
    .matches(/[a-z]/, 'Please enter a valid password')
    .matches(/[A-Z]/, 'Please enter a valid password')
    .matches(/[0-9]/, 'Please enter a valid password')
    .matches(/[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/, 'Please enter a valid password')
    .required('Please enter a valid password'),
  confirmPassword: Yup.string()
    .required('Those two passwords do not match. Please try again.')
    .oneOf([Yup.ref('password'), null], 'Those two passwords do not match. Please try again.'),
  code: Yup.string()
    .min(6, 'Please enter a valid code 6 digits')
    .max(6, 'Please enter a valid code 6 digits')
    .required('Please enter a valid code'),
});

export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Please enter a valid password')
    .max(128, 'Please enter a valid password. The field allows up to 128 characters.')
    .matches(/[a-z]/, 'Please enter a valid password')
    .matches(/[A-Z]/, 'Please enter a valid password')
    .matches(/[0-9]/, 'Please enter a valid password')
    .matches(/[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/, 'Please enter a valid password')
    .required('Please enter a valid password'),
  confirmPassword: Yup.string()
    .required('Those two passwords do not match. Please try again.')
    .oneOf([Yup.ref('password'), null], 'Those two passwords do not match. Please try again.'),
  oldPassword: Yup.string().required('Please enter your old password'),
});

export const ForceChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please enter a new password'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'The two password fields didn’t match.')
    .required('Required'),
});

const extensions = Yup.number()
  .integer('Please enter a valid extension number')
  .min(0, 'Please enter a valid extension number')
  .max(99999999, 'Please enter a valid extension number')
  .typeError('Please enter a valid extension number')
  .nullable();

const addressSchemaShape = {
  city: Yup.string().when(['is_online'], {
    is: (online: any) => (online === false),
    then: Yup.string()
      .min(1, 'Please enter a valid city')
      .max(120, 'Please enter a valid city. The field allows up to 120 characters.')
      .required('Please enter a valid city'),
  }),
  postal_code: Yup.string().when(['is_online'], {
    is: (online: any) => (online === false),
    then: Yup.string()
      .matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'Please enter a valid postal code')
      .required('Please enter a valid postal code'),
  }),
  street_address: Yup.string().when(['is_online'], {
    is: (online: any) => (online === false),
    then: Yup.string()
      .min(1, 'Please enter a valid street address')
      .max(120, 'Please enter a valid street address. The field allows up to 120 characters.')
      .required('Please enter a valid street address'),
  }),
};

export const addressOnBlurSchemaShape = Yup.object().shape({
  city: Yup.string()
    .min(1, 'Please enter a valid city')
    .max(120, 'Please enter a valid city. The field allows up to 120 characters.')
    .required('Please enter a valid city'),
  postal_code: Yup.string()
    .matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'Please enter a valid postal code')
    .required('Please enter a valid postal code'),
  street_address: Yup.string()
    .min(1, 'Please enter a valid street address')
    .max(120, 'Please enter a valid street address. The field allows up to 120 characters.')
    .required('Please enter a valid street address'),
});

const addressDraftSchemaShape = {
  city: Yup.string(),
  postal_code: Yup.string(),
  street_address: Yup.string(),
};

const ppoPatientPartnersNewSchemaShape = {
  target_patients: Yup.number()
    .typeError('Please enter a valid number for patients')
    .max(2147483647, 'Please enter a valid number for patients.')
    .required('Please enter a valid number for patients'),
  target_controls: Yup.number()
    .typeError('Please enter a valid number for controls')
    .max(2147483647, 'Please enter a valid number for controls')
    .nullable(),
};

const ppoPatientPartnersEditSchemaShape = {
  target_patients: Yup.number()
    .typeError('Please enter a valid number for patients')
    .max(2147483647, 'Please enter a valid number for patients.')
    .nullable(),
  target_controls: Yup.number()
    .typeError('Please enter a valid number for controls')
    .max(2147483647, 'Please enter a valid number for controls')
    .nullable(),
};

export const rebSchemaShape = Yup.object().shape({
  reb_number: Yup.string()
    .min(1, 'Please enter a valid REB number')
    .max(80, 'Please enter a valid REB number. The field allows up to 80 characters.')
    .required('Please enter a valid REB number'),
  reb_file: Yup.string().required('Please upload a valid REB file'),
  reb_expiry: Yup.string()
    .matches(
      /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
      'Please enter a valid date YYYY-MM-DD',
    )
    .typeError('Please enter a valid reb expiry date YYYY-MM-DD')
    .required('Please enter a valid reb expiry date YYYY-MM-DD'),
});

export const projectSchemaShape = {
  additional_team_member: Yup.array().test(
    'atLeastOneReceiveEmail',
    'At least one team member must receive automated emails',
    (value) => (value === undefined ? false : value.reduce((acc, current) => acc || current.receive_email, false)),
  ),
  title: Yup.string()
    .min(1, 'Please enter a valid title')
    .max(500, 'Please enter a valid title. The field allows up to 500 characters.')
    .required('Please enter a valid title'),
  lay_title: Yup.string()
    .min(1, 'Please enter a valid lay title')
    .max(90, 'Please enter a valid lay title. The field allows up to 90 characters.')
    .required('Please enter a valid lay title'),
  // Nickname is rendered as "Headline" in the UI / how client refers to Nickname
  nick_name: Yup.string()
    .min(1, 'Please enter a valid study headline. This field allows up to 90 characters.')
    .max(90, 'Please enter a valid study headline. This field allows up to 90 characters.')
    .required('Please enter a valid study headline. This field allows up to 90 characters.'),
  is_clinical_trial: Yup.boolean(),
  registration_num: Yup.string()
    .nullable()
    .when('is_clinical_trial', {
      is: true,
      then: Yup.string().nullable().required('Please enter a valid registration number'),
    }),

  funding_agency: Yup.array().min(1, 'Please select a funding agency').required('Please select a funding agency'),
  recruitment_end: Yup.date()
    .required('Please enter a valid recruitment end date YYYY-MM-DD'),
  health_authority_region: Yup.array()
    .min(1, 'Please select at least one health authority region option')
    .required('Please select at least one health authority region option'),

  patient_oriented_project: Yup.boolean().required('Please select if is patient-oriented research project'),
  pp_co_pi: Yup.string()
    .max(200, 'The field allows up to 200 characters.'),
  pp_affiliated_organization: Yup.string()
    .max(200, 'The field allows up to 200 characters.'),
  province: Yup.string()
    .min(1, 'Please enter a valid province')
    .max(2, 'Please enter a valid province. The field allows up to 2 characters.'),
  purpose: Yup.string()
    .min(1, 'Please enter a valid purpose')
    .max(450, 'Please enter a valid purpose. The field allows up to 450 characters.')
    .required('Please enter a valid purpose'),
  // 'description' matches the field with header "what is involved?"
  description: Yup.string()
    .min(1, 'Please enter a valid what is involved description')
    .max(
      700,
      'Please enter a valid what is involved description. The field allows up to 700 characters.',
    )
    .required('Please enter a valid what is involved description'),
  orientation_provided: Yup.string()
    .min(1, 'Please enter a valid orientation description')
    .max(
      300,
      'Please enter a valid orientation description. The field allows up to 300 characters.',
    ),
  background: Yup.string()
    .min(1, 'Please enter a valid background description')
    .max(
      900,
      'Please enter a valid background description. The field allows up to 900 characters.',
    ),
  commitment: Yup.string()
    .min(1, 'Please enter a valid summary of commitment')
    .max(
      300,
      'Please enter a valid summary of commitment. The field allows up to 300 characters.',
    )
    .required('Please enter a valid summary of commitment'),
  procedures_description: Yup.string()
    .max(
      450,
      'Please enter a valid procedures description. The field allows up to 450 characters.',
    ),
  min_age: Yup.number()
    .typeError('Please enter a number')
    .min(0, 'Please enter a valid minimum age')
    .required('Please enter a valid minimum age'),
  max_age: Yup.number()
    .typeError('Please enter a valid maximum age')
    .when('min_age', {
      is: (value: any) => (typeof value === 'number'),
      then: Yup.number().typeError('Please enter a valid maximum age').moreThan(Yup.ref('min_age'), 'Please enter an age greater than the minimum value'),
    })
    .required('Please enter a valid maximum age'),
  sex_eligible: Yup.array().min(1, 'Please select sex').required('Please select sex'),
  genders_eligible: Yup.array()
    .min(1, 'Please select a gender')
    .required('Please select a gender'),
  inclusion_criteria: Yup.string()
    .min(0, 'Please enter a valid inclusion criteria')
    .max(3000, 'Please enter a valid inclusion criteria. The field allows up to 3000 characters.'),
  exclusion_criteria: Yup.string()
    .min(0, 'Please enter a valid exclusion criteria')
    .max(3000, 'Please enter a valid exclusion criteria. The field allows up to 3000 characters.'),
  target_patients: Yup.number()
    .typeError('Please enter a valid number for patients')
    .max(2147483647, 'Please enter a valid number for patients.')
    .required('Please enter a valid number for patients'),
  target_controls: Yup.number()
    .typeError('Please enter a valid number for controls')
    .max(2147483647, 'Please enter a valid number for controls')
    .when('healthy_controls', {
      is: true,
      then: Yup.number().typeError('Please enter a valid number for controls').required('Please enter a valid number for controls'),
    }),
  contact1_name: Yup.string()
    .min(2, 'Please enter a valid name')
    .max(250, 'Please enter a valid name. The field allows up to 250 characters.')
    .required('Please enter a valid name'),
  contact1_position: Yup.string().required('Please choose a position'),
  contact1_email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter a valid email'),
  contact1_phone: Yup.string()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    )
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number. The field allows only 10 characters.')
    .nullable(),
  contact1_phone_extension: extensions,
  contact2_name: Yup.string()
    .max(250, 'Please enter a valid name. The field allows up to 250 characters.')
    .nullable(),
  contact2_email: Yup.string().email('Please enter a valid email').nullable(),
  contact2_phone: Yup.string().nullable()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    )
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number. The field allows only 10 characters.'),
  contact2_phone_extension: extensions,
  reb_certificate: Yup.boolean(),
  reb_number: Yup.string().when('reb_certificate', {
    is: true,
    then: Yup.string()
      .min(1, 'Please enter a valid REB number')
      .max(80, 'Please enter a valid REB number. The field allows up to 80 characters.')
      .required('Please enter a valid REB number'),
  }),
  reb_file: Yup.string().when('reb_certificate', {
    is: true,
    then: Yup.string()
      .required('Please upload a valid REB file'),
  }),
  reb_expiry: Yup.string().when('reb_certificate', {
    is: true,
    then: Yup.string()
      .matches(
        /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
        'Please enter a valid date YYYY-MM-DD',
      )
      .typeError('Please enter a valid reb expiry date YYYY-MM-DD')
      .required('Please enter a valid reb expiry date YYYY-MM-DD'),
  }),
  health_categories: Yup.array()
    .min(1, 'Please select a health category')
    .required('Please select a health category'),
  onsite_presence_needed: Yup.boolean(),
  pi_id: Yup.number()
    .typeError('A Researcher Account with this email address does not exist. The PI must create a researcher account before this study can be submitted.')
    .required('A Researcher Account with this email address does not exist. The PI must create a researcher account before this study can be submitted.'),
  hospital_id: Yup.number().transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(),
  building: Yup.string()
    .max(120, 'Please enter a valid building. The field allows up to 120 characters.'),
  department: Yup.string()
    .max(120, 'Please enter a valid department. The field allows up to 120 characters.'),
  comments: Yup.string()
    .max(5000, 'Please enter a valid comment. The field allows up to 5000 characters.'),
  bc_support_unit: Yup.boolean(),
  is_online: Yup.boolean(),
  regional_centers: Yup.array().when('bc_support_unit', {
    is: true,
    then: Yup.array().min(1, 'Please select a centre').required('Please select a centre'),
    otherwise: Yup.array().notRequired().nullable(),
  }),
  study_type: Yup.string().required('Please select a research study type'),
  desired_status: Yup.string()
    .min(1, 'Please select your desired project status')
    .required('Please select your desired project status'),
  wasDraft: Yup.boolean(),
  desiredStatusOrignal: Yup.string(),
  has_direct_link: Yup.boolean().required('Please select online status'),
  direct_link_url: Yup.string().when('has_direct_link', {
    is: true,
    then: Yup.string().url('Please enter a vaild url link for your online study')
      .required('Please enter a vaild url link for your online study'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
};

export const projectDraftSchemaShape = {
  title: Yup.string(),
  lay_title: Yup.string(),
  nick_name: Yup.string(),
  is_clinical_trial: Yup.boolean(),
  registration_num: Yup.string().nullable(),
  funding_agency: Yup.array(),
  recruitment_end: Yup.date(),
  health_authority_region: Yup.array(),
  province: Yup.string(),
  purpose: Yup.string(),
  description: Yup.string(),
  orientation_provided: Yup.string(),
  background: Yup.string(),
  commitment: Yup.string(),
  procedures_description: Yup.string(),
  min_age: Yup.number().transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(),
  max_age: Yup.number().transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(),
  sex_eligible: Yup.array(),
  genders_eligible: Yup.array(),
  inclusion_criteria: Yup.string(),
  exclusion_criteria: Yup.string(),
  target_patients: Yup.number(),
  target_controls: Yup.number(),
  contact1_name: Yup.string(),
  contact1_email: Yup.string(),
  contact1_phone: Yup.string().nullable(),
  contact1_phone_extension: extensions,
  contact2_name: Yup.string().nullable(),
  contact2_email: Yup.string(),
  contact2_phone: Yup.string(),
  contact2_phone_extension: extensions,
  reb_certificate: Yup.boolean(),
  reb_number: Yup.string(),
  reb_file: Yup.string(),
  reb_expiry: Yup.string(),
  health_categories: Yup.array(),
  onsite_presence_needed: Yup.boolean(),
  pi_id: Yup.number(),
  hospital_id: Yup.number().transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(),
  building: Yup.string(),
  department: Yup.string(),
  comments: Yup.string(),
  bc_support_unit: Yup.boolean(),
  is_online: Yup.boolean(),
  regional_centers: Yup.array(),
  study_type: Yup.string(),
  type: Yup.string().required(),
  status: Yup.string().required(),
  desired_status: Yup.string(),
  desiredStatusOrignal: Yup.string(),
  has_direct_link: Yup.boolean().required('Please select online status'),
  direct_link_url: Yup.string(),
};

const PPOSchemaShape = {
  additional_team_member: Yup.array().test(
    'atLeastOneReceiveEmail',
    'At least one team member must receive automated emails',
    (value) => (value === undefined ? false : value.reduce((acc, current) => acc || current.receive_email, false)),
  ),
  title: Yup.string()
    .min(1, 'Please enter a valid name')
    .max(500, 'Please enter a valid name. The field allows up to 500 characters.')
    .required('Please enter a valid name'),
  // nick_name is referred to as "Headline" in the browser / by the client
  nick_name: Yup.string()
    .min(1, 'Please enter a valid opportunity headline. This field allows up to 90 characters.')
    .max(90, 'Please enter a valid opportunity headline. This field allows up to 90 characters.')
    .required(
      'Please enter a valid opportunity headline. This field allows up to 90 characters.',
    ),
  registration_num: Yup.string()
    .min(11, 'Please enter a valid registration number')
    .max(11, 'Please enter a valid registration number. The field allows only 11 characters.')
    .nullable(),
  funding_agency: Yup.array().min(1, 'Please select a funding agency').required('Please select a funding agency'),
  recruitment_end: Yup.date()
    .required('Please enter a valid RSVP end date YYYY-MM-DD'),
  health_authority_region: Yup.array()
    .min(1, 'Please select at least one health authority region option')
    .required('Please select at least one health authority region option'),
  province: Yup.string()
    .min(1, 'Please enter a valid province')
    .max(2, 'Please enter a valid province. The field allows up to 2 characters.'),
  purpose: Yup.string()
    .min(1, 'Please enter a valid purpose')
    .max(450, 'Please enter a valid purpose. The field allows up to 450 characters.')
    .required('Please enter a valid purpose'),
  // 'description' matches the field with header "what is involved? / what's involved"
  description: Yup.string()
    .min(1, 'Please enter a valid what is involved description')
    .max(700, 'Please enter a valid what is involved description. The field allows up to 700 characters.')
    .required('Please enter a valid what is involved description'),
  orientation_provided: Yup.string()
    .min(1, 'Please enter a valid orientation description')
    .max(
      300,
      'Please enter a valid orientation description. The field allows up to 300 characters.',
    )
    .required('Please enter a valid orientation description'),
  background: Yup.string()
    .min(1, 'Please enter a valid background description')
    .max(
      900,
      'Please enter a valid background description. The field allows up to 900 characters.',
    )
    .required('Please enter a valid background description'),
  commitment: Yup.string()
    .min(1, 'Please enter a valid summary of commitment')
    .max(
      300,
      'Please enter a valid summary of commitment. The field allows up to 300 characters.',
    )
    .required('Please enter a valid summary of commitment'),
  min_age: Yup.number()
    .typeError('Please enter a valid minimum age')
    .min(0, 'Please enter a valid minimum age')
    .required('Please enter a valid minimum age'),
  max_age: Yup.number()
    .typeError('Please enter a valid maximum age')
    .when('min_age', {
      is: (value: any) => (typeof value === 'number'),
      then: Yup.number().typeError('Please enter a valid maximum age').moreThan(Yup.ref('min_age'), 'Please enter an age greater than the minimum value'),
    })
    .required('Please enter a valid maximum age'),
  level_of_engagement: Yup.array()
    .min(1, 'Please select a level of engagement')
    .typeError('Please select a level of engagement')
    .required('Please select a level of engagement'),
  sex_eligible: Yup.array().min(1, 'Please select sex').required('Please select sex'),
  genders_eligible: Yup.array()
    .min(1, 'Please select a gender')
    .required('Please select a gender'),
  additional_eligibility: Yup.string()
    .min(1, 'Please enter valid additional eligibility criteria')
    .max(
      700,
      'Please enter valid additional eligibility criteria. The field allows up to 700 characters.',
    )
    .required('Please enter valid additional eligibility criteria'),
  contact1_name: Yup.string()
    .min(2, 'Please enter a valid name')
    .max(250, 'Please enter a valid name. The field allows up to 250 characters.')
    .required('Please enter a valid name'),
  contact1_position: Yup.string().required('Please choose a position'),
  contact1_email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter a valid email'),
  contact1_phone: Yup.string()
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number. The field allows only 10 characters.')
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    )
    .nullable(),
  contact1_phone_extension: extensions,
  contact2_name: Yup.string()
    .max(250, 'Please enter a valid name. The field allows up to 250 characters.')
    .nullable(),
  contact2_email: Yup.string().email('Please enter a valid email').nullable(),
  contact2_phone: Yup.string()
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number. The field allows only 10 characters')
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    )
    .nullable(),
  contact2_phone_extension: extensions,
  pi_id: Yup.number()
    .typeError('A Researcher Account with this email address does not exist. The PI must create a researcher account before this study can be submitted.')
    .required('A Researcher Account with this email address does not exist. The PI must create a researcher account before this study can be submitted.'),
  health_categories: Yup.array()
    .min(1, 'Please select a health category')
    .required('Please select a health category'),
  onsite_presence_needed: Yup.boolean(),
  hospital: Yup.number()
    .integer(),
  building: Yup.string()
    .min(0, 'Please enter a valid building')
    .max(120, 'Please enter a valid building. The field allows up to 120 characters.'),
  department: Yup.string()
    .min(0, 'Please enter a valid department')
    .max(120, 'Please enter a valid department. The field allows up to 120 characters.'),
  comments: Yup.string()
    .max(5000, 'Please enter a valid comment. The field allows up to 5000 characters.'),
  bc_support_unit: Yup.boolean(),
  regional_centers: Yup.array().when('bc_support_unit', {
    is: true,
    then: Yup.array().min(1, 'Please select a centre').required('Please select a centre'),
    otherwise: Yup.array().notRequired().nullable(),
  }),
  desired_status: Yup.string().required('Please select your desired project status'),
  type: Yup.string().required(),
  status: Yup.string().required(),
  is_online: Yup.boolean(),
  wasDraft: Yup.boolean(),
  desiredStatusOrignal: Yup.string(),
  has_direct_link: Yup.boolean().required('Please select online status'),
  direct_link_url: Yup.string().when('has_direct_link', {
    is: true,
    then: Yup.string().url('Please enter a vaild url link for your online opportunity')
      .required('Please enter a vaild url link for your online opportunity'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  ppo_type: Yup.string().when('new', {
    is: true,
    then: Yup.string().required('Please select related research type.'),
    otherwise: Yup.string().notRequired(),
  }),
};

const PPODraftSchemaShape = {
  title: Yup.string(),
  // nick_name is referred to as "Headline" in the browser / by the client
  nick_name: Yup.string(),
  registration_num: Yup.string().nullable(),
  funding_agency: Yup.array(),
  recruitment_end: Yup.date(),
  health_authority_region: Yup.array(),
  province: Yup.string(),
  purpose: Yup.string(),
  description: Yup.string(),
  orientation_provided: Yup.string(),
  background: Yup.string(),
  commitment: Yup.string(),
  min_age: Yup.number().transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(),
  max_age: Yup.number().transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(),
  level_of_engagement: Yup.array().nullable(),
  sex_eligible: Yup.array(),
  genders_eligible: Yup.array(),
  target_patients: Yup.number(),
  target_controls: Yup.number(),
  additional_eligibility: Yup.string(),
  contact1_name: Yup.string(),
  contact1_position: Yup.string(),
  contact1_email: Yup.string(),
  contact1_phone: Yup.string().nullable(),
  contact1_phone_extension: extensions,
  contact2_name: Yup.string().nullable(),
  contact2_email: Yup.string(),
  contact2_phone: Yup.string(),
  contact2_phone_extension: extensions,
  pi_id: Yup.number().transform((value) => (Number.isNaN(value) ? undefined : value)).nullable(),
  health_categories: Yup.array(),
  onsite_presence_needed: Yup.boolean(),
  hospital: Yup.number(),
  building: Yup.string(),
  department: Yup.string(),
  comments: Yup.string(),
  bc_support_unit: Yup.boolean(),
  regional_centers: Yup.array(),
  desired_status: Yup.string(),
  type: Yup.string().required(),
  is_online: Yup.boolean().nullable(),
  status: Yup.string().required(),
  desiredStatusOrignal: Yup.string(),
  has_direct_link: Yup.boolean().required('Please select online opportunity'),
  direct_link_url: Yup.string(),
  ppo_type: Yup.string(),
};

export const ProjectSchema = Yup.object().shape({
  ...projectSchemaShape,
  ...addressSchemaShape,
});

export const ProjectDraftSchema = Yup.object().shape({
  ...projectDraftSchemaShape,
  ...addressDraftSchemaShape,
});

export const PPOSchema = Yup.object().shape({
  ...PPOSchemaShape,
  ...addressSchemaShape,
  ...ppoPatientPartnersNewSchemaShape,
});

export const PPOEditSchema = Yup.object().shape({
  ...PPOSchemaShape,
  ...addressSchemaShape,
  ...ppoPatientPartnersEditSchemaShape,
});

export const PPODraftSchema = Yup.object().shape({
  ...PPODraftSchemaShape,
  ...addressDraftSchemaShape,
});

export const studyResultSchema = Yup.object().shape({
  completed: Yup.boolean(),
  type: Yup.string().required(),
  summary: Yup.string()
    .required('Please enter a valid summary.')
    .max(900, 'The field allows up to 900 characters.'),
  patient_partners_count: Yup.string().when('patient_oriented_project', {
    is: true,
    then: Yup.string()
      .required('Please enter a valid number.')
      .matches(/^[1-9]\d*$/, 'Please enter a valid number.'),
  }),
  patient_partners_engagement_strategy: Yup.string().when('patient_oriented_project', {
    is: true,
    then: Yup.string()
      .required('Please enter a valid explanation.')
      .max(900, 'The field allows up to 900 characters.'),
  }),
  study_limitations: Yup.string().max(450, 'The field allows up to 450 characters.'),
  resource_website_name: Yup.string().when('website', {
    is: true,
    then: Yup.string()
      .max(256, 'The field allows up to 256 characters.')
      .required('Please enter name of website/linked resource '),
  }),
  resource_website_url: Yup.string().when('resource_website_name', {
    is: (name: string) => name?.length > 0,
    then: Yup.string()
      .url('Please enter valid URL')
      .max(200, 'The field allows up to 200 characters.')
      .required('Please enter valid URL'),
  }).nullable(),
  resource_file_name: Yup.string().when('needFileName', {
    is: true,
    then: Yup.string()
      .max(256, 'The field allows up to 256 characters.')
      .required('Please enter valid file name'),
  }),
  resource_file: Yup.mixed().when('checkFile', {
    is: true,
    then: Yup.mixed()
      .test(
        'fileSize',
        'Max File is 15 MB',
        (value) => !value || (value && value.size <= 15000000),
      ).required('Please select file to upload'),
  }),
  comments: Yup.string()
    .required('Please enter comments')
    .max(512, 'The field allows up to 512 characters.'),
  patient_oriented_project: Yup.boolean(),
});

export const ppoResultOnBlurSchema = Yup.object().shape({
  summary: Yup.string()
    .required('Please enter a valid summary.')
    .max(900, 'The field allows up to 900 characters.'),
  patient_partners_count: Yup.string()
    .required('Please enter a valid number.')
    .matches(/^[1-9]\d*$/, 'Please enter a valid number.'),
  patient_partners_engagement_strategy: Yup.string()
    .required('Please enter a valid explanation.')
    .max(900, 'The field allows up to 900 characters.'),
  comments: Yup.string()
    .required('Please enter comments')
    .max(512, 'The field allows up to 512 characters.'),
  resource_website_name: Yup.string().max(256, 'The field allows up to 256 characters.').nullable(),
  resource_website_url: Yup.string()
    .url('Please enter valid URL')
    .max(200, 'The field allows up to 200 characters.')
    .nullable(),
  resource_file_name: Yup.string().max(256, 'The field allows up to 256 characters.').nullable(),
});

export const studyResultOnBlurSchema = Yup.object().shape({
  summary: Yup.string()
    .required('Please enter a valid summary.')
    .max(900, 'The field allows up to 900 characters.'),
  patient_partners_count: Yup.string()
    .required('Please enter a valid number.')
    .matches(/^[1-9]\d*$/, 'Please enter a valid number.'),
  patient_partners_engagement_strategy: Yup.string()
    .required('Please enter a valid explanation.')
    .max(900, 'The field allows up to 900 characters.'),
  study_limitations: Yup.string().max(450, 'The field allows up to 450 characters.'),
  resource_website_name: Yup.string().max(256, 'The field allows up to 256 characters.').nullable(),
  resource_website_url: Yup.string()
    .url('Please enter valid URL')
    .max(200, 'The field allows up to 200 characters.')
    .nullable(),
  resource_file_name: Yup.string().max(256, 'The field allows up to 256 characters.').nullable(),
  comments: Yup.string()
    .required('Please enter comments')
    .max(512, 'The field allows up to 512 characters.'),
  patient_oriented_project: Yup.boolean(),
});

export const ppoResultSchema = Yup.object().shape({
  completed: Yup.boolean(),
  type: Yup.string().required(),
  linked_study_id: Yup.string().when('link_study', {
    is: true,
    then: Yup.string().required('Please select study.'),
  }),
  summary: Yup.string().when('link_study', {
    is: false,
    then: Yup.string()
      .required('Please enter a valid summary.')
      .max(900, 'The field allows up to 900 characters.'),
  }),
  patient_partners_count: Yup.string().when('link_study', {
    is: false,
    then: Yup.string()
      .required('Please enter a valid number.')
      .matches(/^[1-9]\d*$/, 'Please enter a valid number.'),
  }),
  patient_partners_engagement_strategy: Yup.string().when('link_study', {
    is: false,
    then: Yup.string()
      .required('Please enter a valid explanation.')
      .max(900, 'The field allows up to 900 characters.'),
  }),
  study_limitations: Yup.string().max(450, 'The field allows up to 450 characters.'),
  resource_website_name: Yup.string().when('website', {
    is: true,
    then: Yup.string()
      .max(256, 'The field allows up to 256 characters.')
      .required('Please enter name of website/linked resource '),
  }),
  resource_website_url: Yup.string().when('resource_website_name', {
    is: (name: string) => name?.length > 0,
    then: Yup.string()
      .url('Please enter valid URL')
      .max(200, 'The field allows up to 200 characters.')
      .required('Please enter valid URL'),
  }).nullable(),
  resource_file_name: Yup.string().when('needFileName', {
    is: true,
    then: Yup.string()
      .max(256, 'The field allows up to 256 characters.')
      .required('Please enter valid file name'),
  }),
  resource_file: Yup.mixed().when('checkFile', {
    is: true,
    then: Yup.mixed()
      .test(
        'fileSize',
        'Max File is 15 MB',
        (value) => !value || (value && value.size <= 15000000),
      ).required('Please select file to upload'),
  }),
  comments: Yup.string().when('link_study', {
    is: false,
    then: Yup.string()
      .required('Please enter comments')
      .max(512, 'The field allows up to 512 characters.'),
  }),
  link_study: Yup.boolean(),
});

export const UpdateMyProfileOnBlurOtherSchema = Yup.object().shape({
  ethnicity_other: Yup.string().required('Please specify your ethnicity.').nullable(),
  travel_locations: Yup.array()
    .required('Please select at least one of the location options')
    .min(1, 'Please select at least one of the location options')
    .nullable(),
});

export const UpdateMyProfileSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(1, 'Please enter a valid first name')
    .max(50, 'Please enter a valid first name. The field allows up to 50 characters.')
    .required('Please enter a valid first name'),
  last_name: Yup.string()
    .min(1, 'Please enter a valid last name')
    .max(50, 'Please enter a valid last name. The field allows up to 50 characters.')
    .required('Please enter a valid last name'),
  phone_number: Yup.string()
    .required('You cannot leave this blank')
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number. The field allows only 10 characters.')
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    ),
  birth_date: Yup.string()
    .min(10, 'Please enter a valid birthday')
    .max(10, 'Please enter a valid birthday. The field allows only 10 characters.')
    .matches(
      /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
      'Please enter a valid birthday',
    )
    .required('Please enter a valid birthday'),
  sex: Yup.string()
    .required('Please select a sex.')
    .nullable(),
  gender: Yup.array()
    .min(1, 'Please select a gender.')
    .required('Please select a gender.')
    .nullable(),
  ethnicity: Yup.array()
    .min(1, 'Please select a ethnicity.')
    .required('Please select a ethnicity.')
    .nullable(),
  ethnicity_is_other: Yup.boolean().nullable(),
  ethnicity_other: Yup.string().when('ethnicity_is_other', {
    is: true,
    then: Yup.string().required('Please specify your ethnicity.'),
  }).nullable(),
  for_child: Yup.boolean(),
  child1_birthyear: Yup.string().when('for_child ', {
    is: true,
    then: Yup.string()
      .min(4, 'Please enter a valid birth year')
      .max(4, 'Please enter a valid birth year')
      .required('Please enter a valid birth year'),
  }).nullable(),
  child2_birthyear: Yup.string()
    .min(4, 'Please enter a valid birth year')
    .max(4, 'Please enter a valid birth year')
    .nullable(),
  child3_birthyear: Yup.string()
    .min(4, 'Please enter a valid birth year')
    .max(4, 'Please enter a valid birth year')
    .nullable(),
});

export const UpdateResearchPreferenceSchema = Yup.object().shape({
  affiliated_health_authority_region_id: Yup.string()
    .required('Please select one of the affiliated health region options.').nullable(),
  travel_distance: Yup.string()
    .required('Please select your willingness to travel.').nullable(),
  child1_birthyear: Yup.number()
    .max(9999, 'Please enter a valid year')
    .nullable(),
  child2_birthyear: Yup.number()
    .max(9999, 'Please enter a valid year')
    .nullable(),
  child3_birthyear: Yup.number()
    .max(9999, 'Please enter a valid year')
    .nullable(),
  travel_locations: Yup.array().when('travel_distance', {
    is: 'health_authority',
    then: Yup.array()
      .required('Please select at least one of the location options')
      .min(1, 'Please select at least one of the location options')
      .nullable(),
  }),
  project_type_preference: Yup.array()
    .min(1, 'Please select at least one of the options.')
    .required('Please select at least one of the options.')
    .nullable(),
  health_categories: Yup.array()
    .min(1, 'Please select at least one of the options.')
    .required('Please select at least one of the options.')
    .nullable(),
});

export const UpdateResearcherProfileSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(1, 'Please enter a valid first name')
    .max(50, 'Please enter a valid first name. The field allows up to 50 characters.')
    .required('Please enter a valid first name'),
  last_name: Yup.string()
    .min(1, 'Please enter a valid last name')
    .max(50, 'Please enter a valid last name. The field allows up to 50 characters.')
    .required('Please enter a valid last name'),
  phone_number: Yup.string()
    .required('Please enter a valid phone number')
    .min(10, 'Please enter a valid phone number')
    .max(10, 'Please enter a valid phone number. The field allows only 10 characters.')
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Please enter a valid phone number',
    ),
  extension: Yup.number()
    .integer('Please enter a valid extension number')
    .min(0, 'Please enter a valid extension number')
    .max(99999999, 'Please enter a valid extension number')
    .typeError('Please enter a valid extension number')
    .nullable(),
  position: Yup.string()
    .required('Please select a position')
    .nullable(),
  position_other: Yup.string()
    .nullable()
    .when('position', {
      is: 'Other',
      then: Yup.string()
        .required('Please specify your position')
        .typeError('Please specify your position'),
    }).nullable(),
  ha_affiliation_id: Yup.number()
    .required('Please select a health authority affiliation')
    .nullable(),
  academic_affiliation_id: Yup.number()
    .required('Please select an academic affiliation')
    .nullable(),
  academic_other: Yup.boolean().required(),
  academic_affiliation_other: Yup.string().when('academic_other', {
    is: true,
    then: Yup.string()
      .required('Please specify your academic affiliation')
      .nullable(),
  }).nullable(),
  researcher_term_accept: Yup.boolean()
    .oneOf([true], 'Please accept the Terms of Use')
    .required('Please accept the Terms of Use'),
});

export const PasswordRequirementValidationSchema = Yup.object().shape({
  length: Yup.string().min(8),
  uppercase: Yup.string().matches(/[A-Z]/),
  lowercase: Yup.string().matches(/[a-z]/),
  number: Yup.string().matches(/[0-9]/),
  character: Yup.string().matches(/[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/),
});
