import { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useAPI } from '../../../hooks';
import { usePasswordRequirement } from '../../../hooks/usePasswordRequirement';
import {
  ResearcherOtherFields, PasswordOnBlurSchema, ResearcherInfoSchema, ResearcherEmailSchema, CreateResarcherSchema,
} from '../../../utils/validation';
import {
  GTagHandler, GTAG_ACTION_TYPES, GTAG_CATEGORIES, GTAG_LABEL,
} from '../../../utils';

type universityModel = {
  name: string,
  id: number,
}

type researcherInfoProps = {
  firstName: string,
  lastName: string,
  phoneNumber: string,
  extension?: string,
  mobilePhoneNumber: string,
  position: string,
  positionOther?: string,
  ha_affiliation_id?: string,
  academic_affiliation_id: string,
  academicAffiliationOther?: string,
  terms_of_use?: boolean,
  receive_news?: boolean,
}

export const useCreateResearcherHook = () => {
  const {
    signUp, getUniversities, getAuthorities, checkUserExist,
  } = useAPI();
  const passwordHook = usePasswordRequirement();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreeTermsOfUse, setAgreeTermsOfUse] = useState(false);
  const [receiveNews, setReciveNews] = useState(false);
  const [universities, setUniversities] = useState<universityModel[]>();
  const [healthAuthority, setHealthAuthority] = useState([]);
  const [researcherInfo, setResearcherInfo] = useState<researcherInfoProps>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    mobilePhoneNumber: '',
    position: '',
    ha_affiliation_id: '',
    academic_affiliation_id: '',
  });

  const [smsConsent, setSmsConsent] = useState(false);
  const [researcherInfoError, setResearcherInfoError] = useState<{ [key: string]: string }>({});
  const [isOpen, setIsOpen] = useState(false);

  const handleSingleValidation = async (value: any, field: string, type?: string) => {
    let errors: { [key: string]: string } = {};
    try {
      if (type === 'other') {
        await Yup.reach(ResearcherOtherFields, field).validate(value);
      } else if (type === 'password') {
        await PasswordOnBlurSchema.validate({
          ...value,
        }, { abortEarly: false });
      } else {
        await Yup.reach(ResearcherInfoSchema, field).validate(value);
      }
      errors = researcherInfoError;
      if (type === 'password') {
        delete errors.password;
        delete errors.password_confirm;
      } else {
        delete errors[field];
      }
      setResearcherInfoError({ ...researcherInfoError });
    } catch (error: any) {
      if (type === 'password') {
        error.inner.forEach((err: any) => {
          errors[err.path] = err.message;
        });
        errors.email = researcherInfoError.email || '';
        setResearcherInfoError({ ...errors });
      } else {
        errors[field] = error.message;
        setResearcherInfoError({ ...researcherInfoError, ...errors });
      }
    }
  };

  const captchaRef = useRef();

  const captchaHandler = (value: any) => {
    captchaRef.current = value;
  };

  const checkEmail = async () => {
    try {
      await ResearcherEmailSchema.validate({
        email,
      }, { abortEarly: false });
      await checkUserExist(email.toLowerCase());
      const error = researcherInfoError;
      delete error.email;
      setResearcherInfoError({ ...error });
    } catch (error: any) {
      if (error.inner) {
        setResearcherInfoError({ ...researcherInfoError, email: error.inner[0].errors[0] });
      } else {
        setResearcherInfoError({ ...researcherInfoError, email: error });
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setResearcherInfoError({});
    try {
      await CreateResarcherSchema.validate({
        password,
        email,
        password_confirm: confirmPassword,
      }, { abortEarly: false });
      await checkUserExist(email.toLowerCase());
      setCurrentStep(currentStep + 1);
      GTagHandler(GTAG_ACTION_TYPES.RESEARCHER_SIGN_UP, GTAG_CATEGORIES.RESEARCHER_SIGN_UP, GTAG_LABEL.SIGN_UP_RESEARCHER);
    } catch (error: any) {
      const errors: { [key: string]: string } = {};
      if (error.inner) {
        error.inner.forEach((element: any) => {
          errors[element.path] = element.message;
        });
      }
      if (typeof error === 'string' && error.includes('email')) {
        errors.email = error;
      }
      setResearcherInfoError(errors);
    }
  };

  const handleInfoSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setResearcherInfoError({});
    try {
      await ResearcherInfoSchema.validate({ ...researcherInfo, acceptTerms: agreeTermsOfUse }, { abortEarly: false });
      if (!captchaRef.current) {
        setResearcherInfoError({ recaptcha: 'Please check reCAPTCHA checkbox to complete a verification' });
        return;
      }
      const user = {
        user_type: 'researcher',
        email: email.toLowerCase(),
        password,
        receive_news: receiveNews,
        ...researcherInfo,
      };

      const actionResult = await signUp(user);
      if (actionResult.status === 'success') {
        GTagHandler(GTAG_ACTION_TYPES.RESEARCHER_SIGN_UP_SECOND, GTAG_CATEGORIES.RESEARCHER_SIGN_UP, GTAG_LABEL.SIGNUP_RESEARCHER_2);

        navigate('/login?status=email-verification', { state: { email: email.toLowerCase() } });
      }
      if (actionResult.status === 'failed') {
        throw new Error(actionResult.data.message);
      }
    } catch (error: any) {
      const errors: { [key: string]: string } = {};
      if (error.inner) {
        error.inner.forEach((element: any) => {
          errors[element.path] = element.message;
        });
        setResearcherInfoError(errors);
      }
    }
  };

  const handleChange = (value: string | number | boolean, field: string) => {
    if ((field === 'position' && value !== 'Other') || (field === 'academic_affiliation_id' && value !== 10)) {
      const error = researcherInfoError;
      const errorToDelete = (field === 'position') ? 'positionOther' : 'academicAffiliationOther';
      delete error[errorToDelete];
      setResearcherInfoError({ ...error });
    }
    if (field === 'ha_affiliation_id' || field === 'position' || field === 'academic_affiliation_id' || field === 'terms_of_use') handleSingleValidation(value, field);
    setResearcherInfo((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const getUniversitiesList = async () => {
    const universitiesOption = await getUniversities();
    setUniversities(universitiesOption);
  };

  const getHealthAuthoritiesList = async () => {
    const authorities = await getAuthorities();
    setHealthAuthority(authorities);
  };

  useEffect(() => {
    getUniversitiesList();
    getHealthAuthoritiesList();
  }, []);

  return {
    passwordHook,

    currentStep,
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    agreeTermsOfUse,
    setAgreeTermsOfUse,
    receiveNews,
    setReciveNews,
    universities,
    healthAuthority,
    researcherInfo,
    researcherInfoError,
    smsConsent,
    setSmsConsent,
    isOpen,
    setIsOpen,
    captchaRef,

    handleSubmit,
    handleInfoSubmit,
    handleChange,
    handleSingleValidation,
    checkEmail,
    captchaHandler,
  };
};
