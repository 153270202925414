import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './routes';
import './utils/faLibrary';
import './styles/reset.css';
import './styles/fonts.css';
import { initGA } from './utils/analytics';

initGA();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
);
