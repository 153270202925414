import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../components/Button';
import { LinkComponent } from '../../components/Link';
import { TextP } from '../../components/Text';
import Toast from '../../components/Toast';
import WebsiteAppWrapper from '../../components/WebsiteAppWrapper';
import { useProjectInterestEmail } from '../../hooks';
import * as Styled from '../../styles/ProjectInterestEmailStyles';
import { PROJECT_INTEREST_EMAIL } from './const';

const ProjectInterestEmail = () => {
  const location: any = useLocation();
  const {
    project,
    createProjectInterest,
    deleteProjectInterest,
    setToastIsOpen,
    toastIsOpen,
    toastMessage,
    error,
    isLoading,
  } = useProjectInterestEmail();

  useEffect(() => {
    createProjectInterest(new URLSearchParams(location.search));
  }, [location]);

  return (
    <WebsiteAppWrapper>
      <Styled.BodyStyled>
        {!isLoading && (
          <div>
            <div>
              {project && (
                <TextP>
                  {project.type === 'study'
                    ? PROJECT_INTEREST_EMAIL.TYPE.STUDY
                    : PROJECT_INTEREST_EMAIL.TYPE.PPO}
                  {project.title}
                </TextP>
              )}
              <Styled.MessageStyled>
                {error ? (
                  <TextP>
                    {PROJECT_INTEREST_EMAIL.FAULTED}
                  </TextP>
                ) : (
                  <TextP>
                    {PROJECT_INTEREST_EMAIL.SUCCESS}
                  </TextP>
                )}
              </Styled.MessageStyled>
            </div>
            <Styled.ButtonWrapper>
              <LinkComponent url="/" theme="buttonThird">
                <div>
                  {PROJECT_INTEREST_EMAIL.VIEW_MATCHES}
                </div>
              </LinkComponent>
            </Styled.ButtonWrapper>

            {project && (
              <Styled.FooterWrapper>
                <Styled.FooterTextWrapper>
                  <TextP>{PROJECT_INTEREST_EMAIL.UNDO_MESSAGE}</TextP>
                </Styled.FooterTextWrapper>
                <Button
                  id="restoreMatch"
                  onClick={() => deleteProjectInterest(new URLSearchParams(location.search))}
                  theme="underline"
                >
                  <TextP>{PROJECT_INTEREST_EMAIL.UNDO_BUTTON}</TextP>
                </Button>
              </Styled.FooterWrapper>
            )}
          </div>
        )}
      </Styled.BodyStyled>
      <Toast
        message={toastMessage}
        isOpen={toastIsOpen}
        setIsOpen={setToastIsOpen}
        checkmark
      />
    </WebsiteAppWrapper>
  );
};

export default ProjectInterestEmail;
