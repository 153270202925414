import ReactGA from 'react-ga4';

// event_category
export const GTAG_CATEGORIES = {
  LOGIN: 'login',
  SIGNUP: 'volunteer_sign_up',
  RESEARCHER_SIGN_UP: 'researcher_sign_up',
  TEST: 'test',
};

// event type
export const GTAG_ACTION_TYPES = {
  CLICK: 'click',
  TEST: 'test',
  SIGN_UP: 'volunteer_sign_up',
  RESEARCHER_SIGN_UP: 'researcher_sign_up',
  RESEARCHER_SIGN_UP_SECOND: 'researcher_info_sign_up',
  LOGIN: 'login',
};

// Event label
export const GTAG_LABEL = {
  SIGN_UP_RESEARCHER: 'sign_up_researcher_-_enter_email',
  SIGNUP_RESEARCHER_2: 'Sign_up_researcher_-_researcher_info',
  SIGN_UP_VOLUNTEER: 'sign_up_volunteer',
  LOGIN: 'login',
  LOGIN_RESEARCHER: 'login_researcher',
  LOGIN_MFA: 'login_mfa',
};

export const GTagHandler = (action: string, category: string, label: string) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
