import { useState, useEffect } from 'react';
import { useAPI } from './useAPI';
import { useGenericCookies } from './useGenericCookies';
import {
  PROJECT_COUNTS, PROJECT_LISTS, TABS, VOLUNTEER_DASHBOARD,
} from '../components/VolunteerDashboard/const';
import { SOURCE_TYPES } from '../utils';

export const useVolunteerDashboard = (user: IUser, searchValue: string) => {
  const {
    getProjects,
    createProjectInterest,
    updateProjectInterest,
  } = useAPI();

  const cookieHook = useGenericCookies();

  const [currentTab, setCurrentTab] = useState<{ label: string, description?: string, value: string }>(VOLUNTEER_DASHBOARD.TABS[0]);

  const [pageNumber, setPageNumber] = useState({ label: '1', value: '1' });
  const [rowCount, setRowCount] = useState(10);
  const rowOption = [5, 10, 20, 25, 50, 100];

  const [projectDataAll, setProjectDataAll] = useState(PROJECT_LISTS);
  const [projectsData, setProjectsData] = useState<{
    suggested: any[],
    project: any[],
    archived: any[],
  }>(PROJECT_LISTS);

  const [totalPages, setTotalPages] = useState(1);
  const [projectTypeCounts, setProjectTypeCounts] = useState(PROJECT_COUNTS);

  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastUndo, setToastUndo] = useState('');
  const [toastProject, setToastProject] = useState({ name: '', id: 0 });

  const [interestType, setInterestType] = useState<{ [key: string]: { value: string, label: string } }>({ 0: { value: 'delete', label: '' } });

  const [interestConfirmationModal, setInterestConfirmationModal] = useState({ projectName: '', projectId: 0, type: '' });
  const [modelIsOpen, setModelIsOpen] = useState(false);

  const [isLoading, setIsloading] = useState(true);

  const handleRowCount = (rowObject: { value: string, label: string }) => {
    setRowCount(Number(rowObject.value));
    cookieHook.createCookie('ReachRowCount', `${Number(rowObject.value)}`, '2100-01-01');
  };

  const getProjectsData = async () => {
    const projects = await getProjects(user.type);
    if (!projects.error) {
      setProjectDataAll({
        archived: projects?.projectArchived || [],
        project: projects?.projectInterests || [],
        suggested: projects.projectMatches || [],
      });
    }
    setIsloading(false);
  };

  const pageanteArray = (array: any[]) => array.slice((parseInt(pageNumber.value, 10) - 1) * rowCount, ((parseInt(pageNumber.value, 10) - 1) * rowCount) + rowCount);

  const searchProjectHandler = () => {
    if (searchValue === '') setProjectsData(projectDataAll);

    let { archived, project, suggested } = projectDataAll;

    archived = archived.filter((data: { title: string; }) => data.title.toLowerCase().includes(searchValue.toLowerCase()));

    project = project.filter((data: { title: string; }) => data.title.toLowerCase().includes(searchValue.toLowerCase()));

    suggested = suggested.filter((data: { title: string; }) => data.title.toLowerCase().includes(searchValue.toLowerCase()));

    setProjectsData({
      archived: archived.length <= rowCount ? archived : pageanteArray(archived),
      project: project.length <= rowCount ? project : pageanteArray(project),
      suggested: suggested.length <= rowCount ? suggested : pageanteArray(suggested),
    });

    setProjectTypeCounts({
      ARCHIVED: archived.length,
      PROJECT: project.length,
      SUGGESTED: suggested.length,
    });
  };

  // SET INTEREST FUNCTIONALITY
  const handleConfirmationModel = (projectID: number, interest: string, projectName: string) => {
    setInterestConfirmationModal({
      projectName,
      projectId: projectID,
      type: interest,
    });
    if (interest === 'withdrawInterest') {
      setModelIsOpen(true);
    }
  };

  const handleProjectConfirmation = async (id?: number, type?: string, name?: string) => {
    const projectInterestType = interestConfirmationModal.type || type;
    const projectId = interestConfirmationModal.projectId || id;
    const projectName = interestConfirmationModal.projectName || name;
    if (!projectId) return;

    setToastIsOpen(false);
    let updatedValues: any = {};
    const date = new Date();
    try {
      switch (projectInterestType) {
        case 'interested':
          await createProjectInterest({
            projectID: projectId, interest: 'interested', created_at: date, source: SOURCE_TYPES.APP,
          });
          updatedValues = { interest: 'interested' };
          setToastMessage('interestedCreate');
          break;
        case 'not_interested':
          await createProjectInterest({
            projectID: projectId, interest: 'not_interested', created_at: date, source: SOURCE_TYPES.APP,
          });
          setToastUndo('notInterested');
          updatedValues = { value: 'not_interested', label: "I'm not interested" };
          setToastMessage('interestedDelete');
          break;
        case 'not_eligible':
          await createProjectInterest({
            projectID: projectId, interest: 'not_eligible', created_at: date, source: SOURCE_TYPES.APP,
          });
          setToastMessage('interestedDelete');
          updatedValues = { value: 'not_eligible', label: "I'm not eligible" };
          setToastUndo('notEligible');
          break;
        case 'archived':
          await updateProjectInterest({
            projectID: projectId, archived: true, updated_at: date, source: SOURCE_TYPES.APP,
          });
          setToastMessage('interestedArchived');
          updatedValues = { archived: 'true' };
          setToastUndo('archived');
          break;
        case 'unarchive':
          await updateProjectInterest({
            projectID: projectId, archived: false, updated_at: date, source: SOURCE_TYPES.APP,
          });
          setToastMessage('interestedUnarchived');
          updatedValues = { archived: 'false' };
          setToastUndo('unarchive');
          break;
        case 'withdrawInterest':
          await updateProjectInterest({
            projectID: projectId, interest: 'withdraw_interest', updated_at: date, source: SOURCE_TYPES.APP,
          });
          setToastMessage('withdrawInterest');
          updatedValues = { interest: 'width_drawn' };
          setToastUndo('withdrawInterest');
          break;
        default:
          throw new Error(`${projectInterestType} is not a valid interest`);
      }
      if (projectName) {
        setToastProject({ name: projectName, id: projectId });
      }
      setModelIsOpen(false);
      if (projectInterestType !== 'interested' || (user.interest_toast_message === true && projectInterestType === 'interested')) {
        setToastIsOpen(true);
      }

      getProjectsData();

      const updateInterestType = interestType;
      updateInterestType[projectId] = updatedValues;
      setInterestConfirmationModal({ projectName: '', projectId: 0, type: '' });
      setInterestType(updateInterestType);
      return updatedValues;
    } catch (error) {
      return false;
    }
  };

  const handleToastUndo = async (projectID?: number) => {
    if (!projectID) return;
    let updatedValues: any = {};

    try {
      const date = new Date();

      switch (toastUndo) {
        case 'notInterested':
          await updateProjectInterest({
            projectID, interest: 'delete', updated_at: date, source: SOURCE_TYPES.APP,
          });
          setToastMessage('interestedDeleteUndo');
          updatedValues = { value: '', label: 'interested', interest: 'withdraw_interest' };
          break;
        case 'notEligible':
          await updateProjectInterest({
            projectID, interest: 'delete', updated_at: date, source: SOURCE_TYPES.APP,
          });
          setToastMessage('interestedDeleteUndo');
          updatedValues = { value: '', label: 'Delete' };
          break;
        case 'archived':
          await updateProjectInterest({
            projectID, archived: false, updated_at: date, source: SOURCE_TYPES.APP,
          });
          setToastMessage('interestedArchivedUndo');
          updatedValues = { archived: 'false' };
          break;
        case 'unarchive':
          await updateProjectInterest({
            projectID, archived: true, updated_at: date, source: SOURCE_TYPES.APP,
          });
          updatedValues = { archived: 'true' };
          setToastMessage('interestedUnarchivedUndo');
          break;
        case 'withdrawInterest':
          await createProjectInterest({
            projectID, interest: 'interested', created_at: date, source: SOURCE_TYPES.APP,
          });
          updatedValues = { interest: 'interested' };
          setToastMessage('withdrawInterestUndo');
          break;
        default:
          throw new Error('Undo interest active failed');
      }

      getProjectsData();

      setInterestType(updatedValues);
      return updatedValues;
    } catch (error) {
      return false;
    }
  };

  const handleButtonClick = (id: number, type: string, projectName: string) => {
    if (type === 'withdrawInterest') {
      handleConfirmationModel(id, type, projectName);
    } else {
      handleProjectConfirmation(id, type, projectName);
    }
  };

  const handleGetRowCount = () => {
    const rowNumber = cookieHook.getCookie('ReachRowCount');
    setRowCount(Number(rowNumber) || 10);
  };

  const setCurrentTabHandler = (tab: { label: string, description?: string, value: string }) => {
    setCurrentTab(tab);
    setPageNumber({ label: '1', value: '1' });
  };

  useEffect(() => {
    if (!user.id) return;

    getProjectsData();
  }, [JSON.stringify(user)]);

  // OVERALL PAGINATOR LOGIC
  useEffect(() => {
    let pageNumberCount = 1;
    switch (currentTab.value) {
      case TABS.SUGGESTED:
        pageNumberCount = Math.ceil(projectTypeCounts.SUGGESTED / rowCount);
        break;
      case TABS.PROJECT:
        pageNumberCount = Math.ceil(projectTypeCounts.PROJECT / rowCount);
        break;
      case TABS.ARCHIVED:
        pageNumberCount = Math.ceil(projectTypeCounts.ARCHIVED / rowCount);
        break;
      default:
    }
    setTotalPages(pageNumberCount < 1 ? 1 : pageNumberCount);
  }, [rowCount, currentTab, JSON.stringify(projectTypeCounts)]);

  useEffect(() => {
    handleGetRowCount();
  }, []);

  useEffect(() => {
    if (parseInt(pageNumber.value, 10) > totalPages) setPageNumber({ label: '1', value: '1' });
  }, [totalPages]);

  useEffect(() => {
    searchProjectHandler();
  }, [searchValue, JSON.stringify(projectDataAll), pageNumber]);

  return {
    handleConfirmationModel,
    projectTypeCounts,
    currentTab,
    setCurrentTab: setCurrentTabHandler,
    projectsData,
    rowCount,
    setRowCount,
    pageNumber,
    setPageNumber,
    totalPages,
    rowOption,
    handleRowCount,
    toastIsOpen,
    setToastIsOpen,
    toastMessage,
    handleToastUndo,
    toastProject,
    interestType,
    modelIsOpen,
    setModelIsOpen,
    interestConfirmationModal,
    handleProjectConfirmation,
    handleGetRowCount,
    handleButtonClick,
    searchProjectHandler,
    isLoading,
  };
};
