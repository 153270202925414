import ReactGA from 'react-ga4';
import environment from '../config/environment';

const GA_TRACKING_ID = environment.app.googleAnalyticTrackingId;

export const initGA = (): void => {
  ReactGA.initialize(GA_TRACKING_ID);
};

export const logPageView = (): void => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};
